<template>
  <div class="oil-box">
    <img src="http://img.ycloudvip.com/attach/2022/03/06885202203221820566871.jpeg" alt="">
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.oil-box img{
    width: 100%;
    height: 100%;
}
</style>
